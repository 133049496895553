import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routers = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/pages/index'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/pages/home/index'),
        meta: {
          title: 'home',
          icon: ''
        }
      },
      {
        path: '/user/center',
        name: 'userCenter',
        component: () => import('@/pages/user/center'),
        meta: {
          title: 'userCenter',
          icon: ''
        }
      },
      {
        path: '/user/orders/orders',
        name: 'userOrders',
        component: () => import('@/pages/user/orders/orders'),
        meta: {
          title: 'userOrders',
          icon: ''
        }
      },
      {
        path: '/user/orders/detail',
        name: 'userOrderDetail',
        component: () => import('@/pages/user/orders/detail'),
        meta: {
          title: 'userOrderDetail',
        }
      },
      {
        path: '/user/daos',
        name: 'userDaos',
        component: () => import('@/pages/user/daos'),
        meta: {
          title: 'userDaos',
          icon: ''
        }
      },
      {
        path: '/markets',
        name: 'markets',
        component: () => import('@/pages/markets/index'),
        meta: {
          title: 'markets',
          icon: ''
        }
      },
      {
        path: '/markets/trade',
        name: 'marketsTrade',
        component: () => import('@/pages/markets/trade'),
        meta: {
          title: 'marketsTrade',
          icon: ''
        }
      },
      {
        path: '/dao',
        name: 'dao',
        component: () => import('@/pages/dao/index'),
        meta: {
          title: 'dao',
          icon: ''
        }
      },
      {
        path: '/twitter/callback',
        name: 'twitterCallback',
        component: () => import('@/pages/user/twitter/index'),
        meta: {
          title: 'twitterCallback',
          icon: ''
        }
      },
      {
        path: '/discord/callback',
        name: 'discordCallback',
        component: () => import('@/pages/user/discord/index'),
        meta: {
          title: 'discordCallback',
          icon: ''
        }
      }
    ]
  }
]


const router = new VueRouter({
  mode: 'history',
  routes: routers
})

export default router

// src/utils/commonUtils.js
import networks from "@/assets/json/networks";
import { ethers } from "ethers";
export function convertPrice(price, decimal, fixed, context) {
  let f = 4;
  if (!price) {
    return 0;
  }
  if (fixed) {
    f = fixed;
  }
  return (Number(price) / 10 ** decimal).toFixed(f);
}
export function copy(text, context) {
  context
    .$copyText(text)
    .then(() => {
      context.$toast.success(context.$t("common.tip.copy_success"));
    })
    .catch(() => {
      context.$toast.error(context.$t("common.tip.copy_failed"));
    });
}
export function checkCurrentNetwork(context) {
  let chainId = context.$store.state.ethersWallet.chainId;
  if (!chainId) {
    return false;
  }
  if (chainId != process.env.VUE_APP_DEPLOY_CHAIN_ID) {
    return false;
  }
  return true;
}

export function switchNetwork(context) {
  return new Promise((resolve, reject) => {
    let item = networks.find((item) => {
      return item.chainId == process.env.VUE_APP_DEPLOY_CHAIN_ID;
    });

    if (!item) {
      return reject(new Error("No matching network found"));
    }

    const switchRequest = {
      method: "wallet_switchEthereumChain",
      params: [{ chainId: ethers.toBeHex(item.chainId) }],
    };

    ethereum
      .request(switchRequest)
      .then(() => {
        resolve();
      })
      .catch((switchError) => {
        console.log("switch network error:", switchError);

        if (switchError.code === 4902) {
          const addRequest = {
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: ethers.toBeHex(item.chainId),
                chainName: item.name,
                rpcUrls: [item.rpcUrl],
                nativeCurrency: {
                  name: item.currency,
                  symbol: item.currency,
                  decimals: 18,
                },
                blockExplorerUrls: [item.blockExplorerUrls],
              },
            ],
          };

          ethereum
            .request(addRequest)
            .then(() => {
              resolve();
            })
            .catch((addError) => {
              console.log("add network error:", addError);
              reject(addError);
            });
        } else {
          reject(switchError);
        }
      });
  });
}

// src/utils/timeAgo.js

export function timeAgo(timestamp,context) {
    const now = new Date();
    const secondsPast = (now.getTime() - timestamp) / 1000;
    let language = localStorage.getItem("language")
    if (secondsPast < 60) {
      return `${Math.floor(secondsPast)}${language === 'en' ? 's ago' : '秒前'}`;
    }
    if (secondsPast < 3600) {
      return `${Math.floor(secondsPast / 60)}${language === 'en' ? 'm ago' : '分钟前'}`;
    }
    if (secondsPast < 86400) {
      return `${Math.floor(secondsPast / 3600)}${language === 'en' ? 'h ago' : '小时前'}`;
    }
    if (secondsPast < 2592000) {
      return `${Math.floor(secondsPast / 86400)}${language === 'en' ? 'd ago' : '天前'}`;
    }
    if (secondsPast < 31536000) {
      return `${Math.floor(secondsPast / 2592000)}${language === 'en' ? 'mo ago' : '月前'}`;
    }
    return `${Math.floor(secondsPast / 31536000)}${language === 'en' ? 'y ago' : '年前'}`;
  }
  
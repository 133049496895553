import request from '@/utils/request'


export function getDataList(params) {
    return request({
        url: "/api/base/user/list",
        method: "get",
        params
    })
}

export function updateData(data) {
    return request({
        url: "/api/base/user/update",
        method: "post",
        data
    })
}
export function getDataDetail(params) {
    return request({
        url: "/api/base/user/detail",
        method: "get",
        params
    })
}
export function login(data) {
    return request({
        url: "/api/base/user/login",
        method: "post",
        data
    })
}

export function sendEmailCode(data) {
    return request({
        url: "/api/base/user/email/code",
        method: "post",
        data
    })
}

// third login
export function thirdLogin(data) {
    return request({
        url: "/api/base/third/login",
        method: "post",
        data
    })
}
// third verified
export function thirdVerified(data) {
    return request({
        url: "/api/base/third/verified",
        method: "post",
        data
    })
}
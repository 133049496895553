import { ethers } from "ethers";
import { login } from "@/api/users";
let state = {
  provider: null,
  userInfo: {
    address: null,
    balance: null,
    showAddress: null,
  },
  language: null,
  chainId: null,
  currentNetwork: null,
};
// let signMsg = "Welcome to DeMatch.\n\n\nThis signature just use to login.\n\n\nTimestamp: "
let signMsg = "This signature is used to verify your identity.";
const actions = {
  async connectWalletAction({ commit, dispatch }, payload) {
    if (typeof window.ethereum !== "undefined") {
      try {
        await window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((accounts) => {
            console.log("eth_requestAccounts------------");
            // connect wallet
            let provider = new ethers.BrowserProvider(window.ethereum);
            // const provider = new ethers.BrowserProvider(window.ethereum)
            // let signature = signer.signMessage(signMsg).then(signature => {
            //   console.log(signature);
            //   commit('LOGIN_SUCCESS', { provider, signature })
            // }).catch(error => {
            //   console.log(error);
            //   if (error.info?.error?.code === 4001) {
            //     this._vm.$toast.error('User deinied.')
            //   }
            // });
          })
          .catch((error) => {
            if (error.info?.error?.code === 4001) {
              this._vm.$toast.error("User rejected the request.");
            }
          });
      } catch (error) {
        console.error("Error connecting to MetaMask:", error);
        if (error.info?.error?.code === 4001) {
          this._vm.$toast.error("User rejected the request.");
        }
      }
    } else {
      console.error("MetaMask is not installed");
      this._vm.$toast.error("MetaMask is not installed");
    }
  },
  async loginBackend({ commit, dispatch }, payload) {
    // console.log("loginBackend------------",provider)
    let provider = new ethers.BrowserProvider(payload.walletProvider);

    let loginUser = localStorage.getItem("loginUser");
    if (
      loginUser &&
      JSON.parse(loginUser).address.toLowerCase() ===
        payload.address.toLowerCase()
    ) {
      commit("ALL_LOGIN_SUCCESS", {
        address: payload.address,
        chainId: payload.chainId,
        walletProvider: provider,
      });
      return;
    }
    let loading = this._vm.$loading({
      fullscreen: true,
      text: this._vm.$i18nn.t("common.tip.login_sign_waiting"),
    });
    let signer = await provider.getSigner();
    signer
      .signMessage(signMsg)
      .then(async (signature) => {
        console.log(signature);
        let { data } = await login({
          signature,
          msg: signMsg,
          address: payload.address,
          inviteCode: localStorage.getItem("inviteCode"),
        });
        console.log("login---------data-------", data);
        if (data.code === 1) {
          localStorage.setItem("loginUser", JSON.stringify(data.data));
          localStorage.setItem("token", data.data.token);
          this._vm.$toast.success(this._vm.$i18nn.t("common.tip.loginSuccess"));
          commit("ALL_LOGIN_SUCCESS", {
            address: payload.address,
            chainId: payload.chainId,
            walletProvider: provider,
          });
        } else {
          this._vm.$toast.error(this._vm.$i18nn.t("common.tip.loginError"));
        }
        loading.close();
      })
      .catch((error) => {
        loading.close();
        console.log(error);
        if (error.info?.error?.code === 4001) {
          this._vm.$toast.error("User deinied.");
        }
        payload.disconnectWallet();
      });
  },
};
const mutations = {
  async ALL_LOGIN_SUCCESS(state, payload) {
    state.provider = payload.walletProvider;
    state.chainId = payload.chainId;
    state.userInfo.address = payload.address.toLowerCase();
    state.userInfo.showAddress = `${payload.address.substring(
      0,
      6
    )}...${payload.address.substring(payload.address.length - 4)}`;
    // const filteredNetworks = this._vm.$networks.filter(
    //   network => network.chainId === state.chainId
    // )
    // 获取第一条符合条件的记录
    // state.currentNetwork = state.chainId
    // filteredNetworks.length > 0 ? filteredNetworks[0] : null
    localStorage.setItem("localLogin", true);
    localStorage.setItem("chainId", state.chainId);
  },
  async ACCOUNT_CHANGE(state, payload) {
    let localLogin = localStorage.getItem("localLogin");
    if (!localLogin) {
      return;
    }
    state.userInfo.address = payload.address;
    state.userInfo.showAddress = `${payload.address.substring(
      0,
      6
    )}...${payload.address.substring(payload.address.length - 4)}`;
    console.log("ACCOUNT_CHANGE---", state);
  },
  async CHAIN_CHANGE(state, payload) {
    // state.provider = new ethers.BrowserProvider(window.ethereum)
    state.provider = new ethers.BrowserProvider(payload.walletProvider);
    state.chainId = Number(payload.chainId);
    // const filteredNetworks = this._vm.$networks.filter(
    //   network => network.chainId === state.chainId
    // )
    // state.currentNetwork =
    //   filteredNetworks.length > 0 ? filteredNetworks[0] : null
    // console.log('CHAIN_CHANGE---', state)
    localStorage.setItem("chainId", state.chainId);
  },
  SET_LANGUAGE(state, payload) {
    console.log("---------SET_LANGUAGE-----------", payload.language);
    state.language = payload.language;
  },
  async LOGOUTED(state, payload) {
    console.log("LOGOUTED----------------");
    state.provider = null;
    state.userInfo = {
      address: null,
      balance: null,
      showAddress: null,
    };
    state.chainId = null;
    localStorage.removeItem("localLogin");
    localStorage.removeItem("loginUser");
    localStorage.removeItem("token");
    // console.log(state)
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

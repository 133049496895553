import * as utils from '../utils/commonUtils';
import { timeAgo } from '../utils/timeUtils';

export default {
  install(Vue) {
    console.log('utils:', utils); // Add this line to debug
    
    // Register utility functions as Vue instance methods
    Object.keys(utils).forEach(key => {
      Vue.prototype[`$${key}`] = function(...args) {
        return utils[key](...args, this);
      };
    });

    // Optionally, you can register filters or directives here as well
    Vue.filter('timeAgo', function(value) {
      if (!value) return '';
      return timeAgo(value, this);
    });

    // Register other filters in a similar way
  }
};

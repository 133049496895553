<template>
  <div class="footer flex col center">
    <div class="top-box flex justify-between">
      <div class="left-box">
        <img class="logo" src="@/assets/logo/logo.png" alt="" />
        <div class="social flex justify-between">
          <div
            class="social-item"
            v-for="(item, index) of socialItems"
            :key="index"
          >
            <img :src="require(`@/assets/imgs/footer/${item}`)" />
          </div>
        </div>
      </div>
      <div class="right-box flex justify-between">
        <div class="nav-item flex align-center col" v-for="(item, index) of navs" :key="index">
          <div class="nav-title">
            {{ item.title }}
          </div>
          <div
            class="menu-item-info"
            v-for="(menu, ind) of item.menus"
            :key="ind"
          >
            <div class="menu-title-info">
              {{ menu.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <span>DeMatch© 2024</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterNav",
  data() {
    return {
      socialItems: ["x.png", "discord.png", "tg.png"],
      navs: [
        {
          title: this.$t("pageFooter.title.about"),
          menus: [
            {
              title: this.$t("pageFooter.title.about_m1"),
              url: "home",
            },
          ],
        },
        {
          title: this.$t("pageFooter.title.help"),
          menus: [
            {
              title: this.$t("pageFooter.title.help_m1"),
              url: "help",
            },
          ],
        },
        {
          title: this.$t("pageFooter.title.service"),
          menus: [
            {
              title: this.$t("pageFooter.title.service_m1"),
              url: "invite",
            },
          ],
        },
      ],
    };
  },
  methods: {
    goto(url) {
      if (url == "email") {
      } else {
        window.open(url, "_blank"); // 新窗口打开外链接
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  color: #47494e;
  text-decoration: none;
}

.footer {
  width: 100%;
  margin-top: 100px;
  // height: 80px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
  background: #20232a;

  .top-box {
    height: 300px;
    padding: 60px 0;
    .left-box {
      width: 200px;
      .logo{
        width: 200px;
      }
      .social {
        margin-top: 24px;
        .social-item {
          cursor: pointer;
        }
      }
    }
    .right-box {
      margin-left: 100px;
    //   width: 700px;
      .nav-item {
        width: 200px;
        .nav-title {
          
          font-weight: bold;
          font-size: 14px;
          color: #f6f8f9;
          line-height: 16px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          margin-bottom: 32px;
        }
        .menu-item-info {
          cursor: pointer;
          .menu-title-info {
            font-weight: 400;
            font-size: 14px;
            color: #f6f8f9;
          }
          &:hover {
            .menu-title-info {
              color: $global-color;
            }
          }
        }
      }
    }
  }

  .copyright {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2f2f30;
    height: 60px;
    span {
      color: #fff;
    }

    img {
      margin-left: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 1200px) {
    
    .top-box {
        flex-direction: column !important;
        justify-content: flex-start;

        .left-box{
            order:2;
            margin-top: 50px;
        }
        .right-box{
            margin-left: 0;
            justify-content: flex-start;
            .nav-item{
                align-items: flex-start;
            }
        }
    }
  }
  @media only screen and (max-width: 600px) {
    
    .top-box {
        flex-direction: column !important;
        justify-content: flex-start;

        .left-box{
            padding: 0 20px;
        }
        .right-box{
            .nav-item{
                width: 100%;
                padding: 0 20px;
            }
        }
    }
  }
}
</style>
